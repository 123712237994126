<template>
  <div>
    <b-card>
      <b-row class="mb-1">
        <b-col md="12" class="d-flex justify-content-end">
          <b-button :disabled="!$can('OPERATOR_CATEGORY_ADD')" :to="{ name: 'category-add' }">
            <font-awesome-icon icon="plus" class="mr-50" />
            {{ $t('action.add') }}
          </b-button>
        </b-col>
      </b-row>
      <div class="border-primary rounded">
        <categories-tree />
      </div>
    </b-card>
  </div>
</template>

<script>
import CategoriesTree from '@/views/category/CategoriesTree.vue'

export default {
  name: 'CategoriesList',

  components: {
    CategoriesTree,
  },
}
</script>
